import React from "react";
import {graphql} from "gatsby";

import { IService, IServiceCaseStudy } from "../utils/types";
import Layout from "../components/layout/layout";
import PageHeader from "../components/pageHeader";
import ServiceCards from "../components/services/serviceCards";

export default function Services({data}): JSX.Element {
    const {
        contentfulServicesPage,
        contentfulLayout,
        allContentfulCaseStudy
    } = data;

    const services: Array<IService> = contentfulServicesPage.services.map(s => (
        {
            name: s.serviceName,
            slug: s.serviceSlug,
            definition: s.serviceDefinition,
            shortDescription: s.serviceShortDescription,
            tactics: s.serviceTactics || []
        }
    ));

    const caseStudies: Array<IServiceCaseStudy> = allContentfulCaseStudy.nodes.map(c => (
        {
            title: c.caseStudyTitle,
            slug: c.caseStudySlug,
            serviceSlugs: c.services.map(s => s.serviceSlug),
            clientName: c.client.clientName
        }
    ));

    return (
        <Layout title={contentfulServicesPage.headerTitle}>
            <PageHeader
                description={contentfulServicesPage.headerDescription.headerDescription}
                titleSubtext={contentfulLayout.servicesLinkTitle}
                title={contentfulServicesPage.headerTitle}/>
            <ServiceCards caseStudies={caseStudies} services={services} />
        </Layout>
    );
}

export const query = graphql`
    query {
        contentfulServicesPage {
            headerTitle
            headerDescription {
                headerDescription
            }
            services {
                serviceName
                serviceSlug
                serviceDefinition
                serviceShortDescription
                serviceTactics
            }
        }
        contentfulLayout {
            servicesLinkTitle
        }
        allContentfulCaseStudy {
            nodes {
                caseStudyTitle
                caseStudySlug
                services {
                    serviceSlug
                }
                client {
                    clientName
                }
            }
        }
    }
`;