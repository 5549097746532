import React from "react";
import styled from "@emotion/styled";
import {Link} from "gatsby";

import {md} from "../../utils/breakpoints";

import { IService, IServiceCaseStudy } from "../../utils/types";

const ServiceCardBase = styled.div`
    background: #fff;
    flex: 1 1 calc(50% - 80px);
    margin: 20px;
    padding: 10px;
    min-width: 350px;
`;

const ServiceCardContent = styled.div<any>`
    border: 1px solid ${props => props.altColor ? props.theme.secondaryColor :  props.theme.primaryColor};
    padding: 40px;
    height: 100%;    

    @media (max-width: ${md}px) {
        padding: 20px;
    }
`;

const ServiceName = styled.div<any>`
    font-size: 36px;
    font-family: ${props => props.theme.headerFontFamily};
    color: ${props => props.theme.primaryColor};
`;

const Definition = styled.div<any>`
    font-size: 18px;
    margin-top: 5px;
    color: ${props => props.theme.secondaryColor};
`;

const ShortDescription = styled.div<any>`
    margin: 20px 0;
    color: ${props => props.theme.primaryColor};
`;

const TacticsList = styled.ul<any>`
    display: flex;
    margin: 30px 0;
    list-style: none;
    flex-wrap: wrap;
`;

const TacticsItem = styled.li<any>`
    flex: 0 0 calc(50% - 40px);
    margin: 10px;
`;

const CaseStudiesTitle = styled.div<any>`
    text-transform: uppercase;
`;

const CaseStudiesList = styled.ul<any>`
    list-style: none;
    margin: 8px 0 0;
    padding: 0;
`;

const CaseStudiesListItem = styled.li<any>`
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 8px;
    margin-top: 0;
    margin-left: 0;

    > a {
        color: ${props => props.theme.primaryColor};
    }
`;

export interface IServiceCardProps {
    service: IService;
    altColor: boolean;
    caseStudies: Array<IServiceCaseStudy>;
}

export default function ServiceCard(props: IServiceCardProps): JSX.Element {
    const {service, caseStudies} = props;

    return (
        <ServiceCardBase>
            <ServiceCardContent altColor={props.altColor}>
                <ServiceName>{service.name}</ServiceName>
                <Definition>{service.definition}</Definition>
                <ShortDescription>{service.shortDescription}</ShortDescription>
                <TacticsList>
                    {service.tactics.map(t => (
                        <TacticsItem key={t}>
                            - {t}
                        </TacticsItem>
                    ))}
                </TacticsList>
                <CaseStudiesTitle>
                    Case Studies
                </CaseStudiesTitle>
                <CaseStudiesList>
                    {caseStudies.map(c => (
                        <CaseStudiesListItem key={c.slug}>
                            <Link to={`/case-studies/${c.slug}`}>
                                {c.clientName}
                            </Link>
                        </CaseStudiesListItem>
                    ))}
                </CaseStudiesList>
            </ServiceCardContent>
        </ServiceCardBase>
    );
}