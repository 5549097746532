import React from "react";
import styled from "@emotion/styled";

import { IService, IServiceCaseStudy } from "../../utils/types";
import ServiceCard from "./serviceCard";

const ServiceCardsList = styled.div`
    display: flex;
    max-width: 86em;
    margin: 40px auto 100px;
    padding: 0 20px;
    flex-wrap: wrap;
    justify-content: center;
`;

export interface IServiceCardsProps {
    services: Array<IService>;
    caseStudies: Array<IServiceCaseStudy>;
}

export default function ServiceCards(props: IServiceCardsProps): JSX.Element {
    const {services, caseStudies} = props;

    return (
        <ServiceCardsList>
            {services.map((s, i) => (
                <ServiceCard caseStudies={caseStudies.filter(c => c.serviceSlugs.some(slug => slug === s.slug))} altColor={false} service={s} key={s.slug} />
            ))}
        </ServiceCardsList>
    );
}